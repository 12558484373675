import { template as template_ea1dfbb5213f4afd937ee7694bf91f11 } from "@ember/template-compiler";
const FKLabel = template_ea1dfbb5213f4afd937ee7694bf91f11(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
