import { template as template_970bab2160cb4b248e7b60c55c4c0176 } from "@ember/template-compiler";
const SidebarSectionMessage = template_970bab2160cb4b248e7b60c55c4c0176(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
