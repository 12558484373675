import { template as template_e481e477d8e14e2a85ce98742af12a0b } from "@ember/template-compiler";
const FKText = template_e481e477d8e14e2a85ce98742af12a0b(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
